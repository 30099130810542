import { checkDomain } from "entrijs";
import { http } from "@/helpers/http";
import Logger from "@/helpers/logger";
import {
  getLambdaUrl,
  LAMBDA_ENTITIES,
  LAMBDA_FUNCTION_NAMES,
} from "@/helpers/lambdaHelpers";
const { GET_ENTRI_TOKEN } = LAMBDA_FUNCTION_NAMES;
const { NEO } = LAMBDA_ENTITIES;

class EntriApi {
  constructor() {
    this.http = new http();
    this._entriUrl = getLambdaUrl(NEO, GET_ENTRI_TOKEN);
  }

  getEntriToken() {
    try {
      return this.http.postData(this._entriUrl);
    } catch (e) {
      Logger.error("`getEntriToken` api", e);
      return Promise.reject(e);
    }
  }

  checkDomain(domain, config) {
    try {
      return checkDomain(domain, config);
    } catch (e) {
      Logger.error("`checkDomain` api", e);
      return Promise.reject(e);
    }
  }
}

const entriApi = new EntriApi();

export default entriApi;
