import store from "@/store";
import { resetStore } from "@/storeHelpers";
import authApi from "./auth.api";
import {
  clearAuthSession,
  getAllQueryParams,
  getMilliSeconds,
  proceedForAutoLogin,
  setSafeValues,
  storeLoginData,
} from "@/helpers";
import Logger from "@/helpers/logger";
import { ADMIN_ROLE, ADMIN_TYPE } from "@/helpers/const";

class AuthService {
  async authenticateExternalToken(domain, externalToken) {
    try {
      const data = await authApi.authenticateExternalToken(
        domain,
        externalToken
      );
      if (data) {
        store.dispatch("partnerId", data.partnerId);
        store.dispatch("partnerName", data.companyName);

        smartStorage.authToken = setSafeValues(data, ["token"]);
        smartStorage.partnerId = setSafeValues(data, ["partnerId"]);
        const showAltUserEmail =
          data.adminType === "customer" && !data.userEmail;
        if (showAltUserEmail) {
          smartStorage.showAltUserEmail = showAltUserEmail;
        } else {
          smartStorage.tempUserAltEmail = data.userEmail;
        }
        smartStorage.removeItem("auth");
      }

      return Promise.resolve(data);
    } catch (e) {
      Logger.error("`authenticateExternalToken` service", e);
      return Promise.reject(e);
    }
  }

  async login(email, password, customerId) {
    try {
      const data = await authApi.login(email, password, customerId);
      if (data) {
        smartStorage.loginEmail = email;
        storeLoginData(data);
      }
      return data;
    } catch (e) {
      Logger.error("`login` service", e);
      return Promise.reject(e);
    }
  }

  async logout() {
    try {
      const logoutData = await authApi.logout();
      clearAuthSession();
      resetStore();
      return logoutData;
    } catch (e) {
      Logger.error("`logout` service", e);
      return Promise.reject(e);
    }
  }

  async loginWithJWT(jwt, partnerId, installId) {
    try {
      const data = await authApi.loginWithJWT(jwt, partnerId, installId);
      const { selectedProvisionedDomain } = getAllQueryParams();
      if (selectedProvisionedDomain) {
        data.primaryDomain = selectedProvisionedDomain;
      }

      storeLoginData(data);
      proceedForAutoLogin(data);

      return data;
    } catch (e) {
      Logger.error("`loginWithJWT` service", e);
      return Promise.reject(e);
    }
  }

  async loginWithWebmailToken(email, webmailToken) {
    try {
      const data = await authApi.loginWithWebmailToken(email, webmailToken);

      if (data) {
        storeLoginData(data);
      }

      return Promise.resolve(data);
    } catch (e) {
      Logger.error("`loginWithWebmailToken` service", e);
      return Promise.reject(e);
    }
  }

  async loginWithToken(token, domain) {
    try {
      const data = await authApi.loginWithToken(token);
      if (data) {
        const isCustomer = !data.accountId;
        const supportedDataFormat = {
          token: token,
          partnerId: data.partnerId,
          actor: {
            role: isCustomer
              ? ADMIN_ROLE.DOMAIN_ADMIN
              : ADMIN_ROLE.ACCOUNT_ADMIN,
            attrs: {
              userName: data.name,
            },
          },
          bllUserId: data.userId,
          primaryDomain: domain,
          userEmail: data.userEmail,
          expiryEpochTimestamp: Date.now() + getMilliSeconds(3), // valid for 3hrs
          adminType: isCustomer ? ADMIN_TYPE.CUSTOMER : ADMIN_TYPE.ACCOUNT,
          isDomainAdmin: isCustomer,
        };
        smartStorage.setItem("loginEmail", data.email);
        storeLoginData(supportedDataFormat);
        proceedForAutoLogin(supportedDataFormat);
      }

      return data;
    } catch (e) {
      Logger.error("`loginWithToken` service", e);
      return Promise.reject(e);
    }
  }
}

const authService = new AuthService();

export default authService;
