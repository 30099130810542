import axios from "axios";
import {
  getBundleId,
  getNeoSignupAppUrl,
  getUrl,
  isMobile,
  redirectToSameORDifferentTab,
  returnObjectIfValueExists,
  smartStorage,
} from ".";
import { generateCrid } from "./api";
import { ADMIN_ROLE } from "./const";
import store from "@/store";

// Bit state wise position
export const apiSiteStateBits = [
  "isPublished",
  "isPublishInProgress",
  "isContactFormEnabled",
  "hasEverBeenPublished",
  "isDraft",
  "v1Site",
  "devOpsPreview",
];

export const fetchNeoWebsiteDetails = async () => {
  const baseURL = process.env.VUE_APP_API_BASE_URL;
  const bid = getBundleId();
  const soid = store.getters["site/orderId"];

  try {
    const res = await axios.get("neo/website/siteAttrs", {
      headers: {
        "Content-Type": "application/json",
        "X-CP-Token": smartStorage.getItem("authToken"),
        Authorization: smartStorage.getItem("authToken"),
      },
      baseURL,
      params: { bid, soid, crid: generateCrid("WIR") },
    });
    return res?.data;
  } catch (e) {
    console.error("Error while fetching neo website details", e);
  }
};

export const readFromBitMask = (orderedKeys) => {
  return (value) =>
    orderedKeys.reduce(
      (acc, key, index) =>
        // eslint-disable-next-line no-bitwise
        ({ ...acc, [key]: Boolean(value & (2 ** index)) }),
      {}
    );
};

export const fromSiteState = readFromBitMask(apiSiteStateBits);

// function to derive bitMask flags for each site version
export const getNeoWebsiteFlags = (siteData) => {
  return siteData?.siteAttrs
    ? Object.keys(siteData?.siteAttrs).reduce(
        (acc, version) => ({
          ...acc,
          [version]: fromSiteState(siteData?.siteAttrs?.[version]),
        }),
        {}
      )
    : {};
};

/**
 * function to check whether any of site version is published or not
 * @param {object} siteData - { [siteVersion]: bitMask }
 * @returns boolean
 */
export const isNeoWebsitePublished = (siteData) => {
  const siteVersionAttrMap = getNeoWebsiteFlags(siteData);
  return !!Object.values(siteVersionAttrMap).find((s) => s.isPublished);
};

export const getNeoSiteBuilderUrlProps = () => {
  const isCustomerAccount =
    smartStorage.getItem("role") === ADMIN_ROLE.DOMAIN_ADMIN;
  const accountId = smartStorage.getItem("accountId");
  const url = process.env.VUE_APP_NEO_SITE_BUILDER_URL;
  const token = smartStorage.getItem("authToken");
  const loggedInUserProps = isCustomerAccount
    ? { customer_id: accountId } // If the user is a customer, the 'accountId' value represents the 'customerId'. In this case, the key is set as 'customer_id'
    : { account_id: accountId }; // If the user is an admin, the 'accountId' value is the actual 'accountId'. Here, the key is set as 'account_id'

  return {
    isCustomerAccount,
    accountId,
    url,
    token,
    ...returnObjectIfValueExists("soid", store.getters?.["site/orderId"]),
    ...returnObjectIfValueExists("bid", store.getters?.bundleId),
    ...loggedInUserProps,
  };
};

export const getNeoSiteBuilderUrl = (domain, domainId, action) => {
  const {
    isCustomerAccount,
    url,
    token,
    ...loggedInUserProps
  } = getNeoSiteBuilderUrlProps();
  const isPublishAction = action === "publish";
  return getUrl(isPublishAction ? `${url}edit/` : url, {
    domain,
    domainId,
    ct: token,
    isCustomerAccount,
    source: "admin_panel",
    // ...(action ? { action } : {}), // incase if we need to pass action
    ...loggedInUserProps,
  });
};

export const openNeoSite = (domain, domainId) => {
  window.open(
    getNeoSiteBuilderUrl(domain, domainId),
    isMobile() ? "_blank" : "FlockSite" // in mobile the window name approach doesn't change focus to `FlockSite` tab
  );
};

export const openNeoSiteAndPublish = (domain, domainId) => {
  const siteTabRef = window.open(
    getNeoSiteBuilderUrl(domain, domainId, "publish"),
    isMobile() ? "_blank" : "FlockSite" // in mobile the window name approach doesn't change focus to `FlockSite` tab
  );
  if (!isMobile()) siteTabRef?.location?.reload();
};

export const openNeoSignupAppSitePurchaseFlow = () => {
  const url = getNeoSignupAppUrl("site/industry");
  redirectToSameORDifferentTab(url, true);
};

export const openNeoSignupApp = () => {
  const url = getNeoSignupAppUrl();
  redirectToSameORDifferentTab(url, true);
};
