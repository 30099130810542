import store from "@/store";
import emailApi from "./email.api";
import Logger from "@/helpers/logger";

class EmailService {
  async getEmailList() {
    try {
      const data = await emailApi.getEmailList();
      store.dispatch("currentDomainMailboxDetails", data?.accounts || []);
      return data;
    } catch (e) {
      Logger.error("`getEmailList` service", e);
      return Promise.reject(e);
    }
  }

  async createEmail(payload) {
    try {
      return await emailApi.createEmail(payload);
    } catch (e) {
      Logger.error("`createEmail` service", e);
      return Promise.reject(e);
    }
  }

  async getCatchAll() {
    try {
      return await emailApi.getCatchAll();
    } catch (e) {
      Logger.error("`getCatchAll` service", e);
      return Promise.reject(e);
    }
  }

  async getEmailForwarders() {
    try {
      return await emailApi.getEmailForwarders();
    } catch (e) {
      Logger.error("`getEmailForwarders` service", e);
      return Promise.reject(e);
    }
  }

  async fetchImportTasks() {
    try {
      return await emailApi.fetchImportTasks();
    } catch (e) {
      Logger.error("`fetchImportTasks` service", e);
      return Promise.reject(e);
    }
  }

  getAllSuspensions(accountId) {
    try {
      return emailApi.getAllSuspensions(accountId);
    } catch (e) {
      Logger.error("`getAllSuspensions` service", e);
      return Promise.reject(e);
    }
  }
}

const emailService = new EmailService();

export default emailService;
