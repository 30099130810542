import { medusa } from "@/helpers";

export const getPathName = () => {
  return window.location.pathname;
};

export const markPagePerformance = (
  loadType = "mount",
  pathname = getPathName()
) => {
  const markName = `${pathname}_${loadType}`;
  performance?.mark(markName);
};

export const measurePagePerformance = (pathname = getPathName()) => {
  try {
    let measure = null;
    const markName = `${pathname}_mount`;
    const measureName = markName;
    const directCallName = `__callDirectLand_mount`;
    let pageLoadType = null;
    if (window[directCallName]) {
      window[directCallName] = false;
      measure = performance?.measure(measureName, directCallName);
      pageLoadType = "direct_land";
    } else {
      measure = performance?.measure(measureName, markName);
      pageLoadType = "route_change";
    }

    performance?.clearMeasures();
    const payload = {
      measureName: markName,
      pageLoadType,
      pageMountedTime: measure?.duration,
    };
    medusa.send(
      medusa.createEventObj(
        {
          eventName: "b_performance_checked",
          ...payload,
        },
        smartStorage.getItem("domain"),
        "bundle"
      )
    );
    return payload;
  } catch (err) {
    console.log(err);
  }
};

export const measureInitialAppLoad = () => {
  try {
    let measure = null;
    const markName = `app_load`;
    const measureName = markName;
    const directCallName = `__callDirectLand_load`;
    let pageLoadType = null;
    if (window[directCallName]) {
      window[directCallName] = false;
      measure = performance?.measure(measureName, directCallName);
      pageLoadType = "direct_land";
    } else {
      // do nothing
    }

    performance?.clearMeasures();
    const payload = {
      measureName: markName,
      pageLoadType,
      pageLoadedTime: measure?.duration,
    };
    medusa.send(
      medusa.createEventObj(
        {
          eventName: "b_performance_checked",
          ...payload,
        },
        smartStorage.getItem("domain"),
        "bundle"
      )
    );
    return payload;
  } catch (err) {
    console.log(err);
  }
};
