import $http from "@/helpers/http";
import Logger from "@/helpers/logger";

class EmailApi {
  getEmailList() {
    try {
      return $http.get("panel/account/email/list");
    } catch (e) {
      Logger.error("`getEmailList` api", e);
      return Promise.reject(e);
    }
  }

  createEmail(payload) {
    try {
      return $http.post("panel/account/email/create", payload);
    } catch (e) {
      Logger.error("`createEmail` api", e);
      return Promise.reject(e);
    }
  }

  getCatchAll() {
    try {
      return $http.get("panel/domain/catchall/get");
    } catch (e) {
      Logger.error("`getCatchAll` api", e);
      return Promise.reject(e);
    }
  }

  getEmailForwarders() {
    try {
      return $http.get("panel/account/forwarder/list");
    } catch (e) {
      Logger.error("`getEmailForwarders` api", e);
      return Promise.reject(e);
    }
  }

  fetchImportTasks() {
    try {
      return $http.get("panel/mail-import/fetch-import-tasks");
    } catch (e) {
      Logger.error("`fetchImportTasks` api", e);
      return Promise.reject(e);
    }
  }

  getAllSuspensions(accountId) {
    try {
      return $http.get(
        `panel/account/email/get-all-suspensions?accountId=${accountId}`
      );
    } catch (e) {
      Logger.error("`getAllSuspensions` api", e);
      return Promise.reject(e);
    }
  }
}

const emailApi = new EmailApi();

export default emailApi;
