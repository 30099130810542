import axios from "axios";

import smartStorage from "./smart-storage";
import eventBus from "./event-bus";
import { isEuRegion } from "./regions";
import { getQueryParam } from "./url";
import { getBundleId, getDomainName } from "./auth";
import { getUserAgentHeader } from "./userAgent";

/**
 * Function to return BLL base url
 * this was added support dynamic bll base
 * @returns bll url
 */
export function getBLLBaseURL() {
  const qBLLBaseURL = getQueryParam("bll_base_url");
  let baseUrl = process.env.VUE_APP_API_URL;

  if (isEuRegion) {
    smartStorage.setItem(
      "bll_base_url",
      decodeURIComponent(process.env.VUE_APP_EU_API_URL)
    );
    baseUrl = process.env.VUE_APP_EU_API_URL;
  }

  if (qBLLBaseURL) {
    smartStorage.setItem("bll_base_url", decodeURIComponent(qBLLBaseURL));
    baseUrl = qBLLBaseURL;
  }

  return baseUrl;
}

export function buildQueryParamsFromList(list, param) {
  return list.reduce((acc, e, i) => `${acc}${i ? "&" : ""}${param}=${e}`, "");
}

/**
 * Appends the common query parameters to requests.
 * @returns The `params` object which contains all common query params
 */
export function getCommonParams() {
  const params = {};
  const domain = getDomainName();
  const bundleId = getBundleId();

  if (domain) {
    params["domain"] = domain;
    params["domainName"] = domain;
  }

  if (bundleId) params["bundleId"] = bundleId;

  return params;
}

const baseUrl = getBLLBaseURL(); // FIXME remove temp BASE_URL

const $http = axios.create({
  baseURL: baseUrl,
});

$http.interceptors.request.use(function insertHeader(config) {
  config.headers = {
    "X-Auth": "controlPanel",
    "X-User-Agent": getUserAgentHeader(),
    ...config.headers,
  };

  const authToken = smartStorage.authToken;
  if (authToken) {
    config.headers["Authorization"] = authToken;
  }
  config.params = {
    ...getCommonParams(),
    ...config.params,
  };

  return config;
});

$http.interceptors.response.use(
  /* Send the actual data to then() */
  (response) => response?.data,
  function (error) {
    const { response } = error;
    if (!response) {
      console.log("client side network error");
      return;
    }
    if (response.status === 401) {
      // Whenever "go-to-login" is emitted, wwe clear the storage and take the user to the login page.
      eventBus.$emit("go-to-login");
    }
    return Promise.reject(response);
  }
);

export default $http;
export function http() {
  this.axios = axios.create({
    baseURL: baseUrl,
  });
}

http.prototype.getData = function (
  url,
  headers,
  successCallback = () => {},
  errorCallback = () => {},
  params = {}
) {
  return this.axios
    .get(url, {
      ...headers,
      params: params,
    })
    .then(({ data }) => {
      successCallback(data);

      return data;
    })
    .catch((data) => {
      var response = data.response;
      errorCallback(response);
      if (!response) {
        console.log("client side network error");
        return;
      }
      if (response.status === 401) {
        eventBus.$emit("go-to-login");
      }

      return data;
    });
};

http.prototype.postData = function (
  url,
  params,
  headers = {},
  successCallback = () => {},
  errorCallback = () => {}
) {
  return this.axios
    .post(url, params, headers)
    .then(({ data }) => {
      successCallback(data);
      return data;
    })
    .catch((data) => {
      var response = data.response;
      errorCallback(response);
      if (!response) {
        console.log("client side network error");
        return;
      }
      if (response.status === 401) {
        eventBus.$emit("go-to-login");
      }

      return data;
    });
};
