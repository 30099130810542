<template>
  <Loader />
</template>

<script>
import brand from "../../helpers/brand";
import Loader from "../Utility/Loader";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import { sentryCaptureException } from "@/helpers/sentry";
import authService from "@/services/auth/auth.service";
import { AUTH_STATE, LOGIN_TYPE } from "@/helpers/const";
import { shouldShowResponsiveCP } from "@/helpers";

/**
 * component responsible for auto login via webmail
 * required query params
 * - token: webmail token
 * - email: mailbox email
 * other query params
 * - redirect: redirection route if mx and spf not set
 * - section: after login where to redirect
 * - action: after login what action to take (check App.vue for action and section)
 * - locale: lang
 */
export default {
  name: "LoginFromWebmail",
  components: { Loader },
  data() {
    return {
      redirectRoute: "",
      isTitanMail: null,
      brandDetails: {},
      queryParams: {},
    };
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    const isMobileBlockerScreenVisible = !shouldShowResponsiveCP();
    if (this.authState === AUTH_STATE.AUTH || isMobileBlockerScreenVisible)
      return;

    this.setBrandDetails();
    const webmailToken = this.$route.query.token;
    const email = this.$route.query.email;
    this.queryParams = this.$route.query;
    this.redirectRoute = this.$route.query.redirect
      ? this.$route.query.redirect
      : "";
    this.handleWebmailLogin(webmailToken, email);
    if (this.$route.query.locale) {
      smartStorage.lang = this.$route.query.locale;
    }
    let query = Object.assign({}, this.$route.query);
    query = {};
    this.$router.replace({ query });
  },
  props: {
    setOfacParams: Function,
    authState: String,
  },
  methods: {
    setBrandDetails() {
      this.isTitanMail =
        this.isTitanMail === null
          ? brand.deriveBrandFromURL() === brand.NAMES.TITAN
          : this.isTitanMail;
      this.brandDetails = brand.getDetails();
    },
    handleWebmailLogin(webmailToken, email) {
      return authService
        .loginWithWebmailToken(email, webmailToken)
        .then(this.onSuccess)
        .catch(this.onError);
    },
    showLoginErrorScreen(err) {
      console.error("auto login failed with error: ", err);
      sentryCaptureException(
        err,
        { loginFailure: "webmailLogin" },
        {
          query: JSON.stringify({
            email: this.queryParams?.email,
            redirect: this.queryParams?.redirect,
          }),
        }
      );
      this.$router.replace({ name: "LoginFailureScreen" });
    },
    onSuccess(data) {
      if (data) {
        this.$emit("onLoginSuccess", LOGIN_TYPE.LOGIN_WITH_WEBMAIL_TOKEN);
      }
    },
    onError(err) {
      if (err?.data?.code === "OFAC_SANCTIONED_COUNTRY") {
        this.setOfacParams({
          isOfacFlag: true,
          ofacDocumentation: this.brandDetails.ofacDocumentation,
          supportEmail: this.brandDetails.supportEmail,
          brandLogo: this.brandDetails.logo,
        });
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "ofac_login_denied",
              ofac_country: err.data.attrs.countryName,
              // is_first_login
            },
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
      } else {
        this.showLoginErrorScreen(err);
      }
    },
  },
};
</script>
