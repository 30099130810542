<template>
  <div class="domain-switcher" :class="type">
    <div
      :class="{
        'domain-dropdown': true,
        open: flags.showDomainSwitcherDropdown,
      }"
      @click="toggleDomainSwitcherDropdown"
      v-on-clickaway="closeDomainSwitcherDropdown"
    >
      {{ defaultDomainName }}
    </div>
    <div
      class="domain-dropdown-items"
      v-show="flags.showDomainSwitcherDropdown"
    >
      <div v-if="showDomainList" class="domain-list">
        <div
          class="switch-domain"
          v-if="flags.isDesktopSwitcher && domainList.length > 1"
        >
          {{ $t("pageHeader.switchDomain") }}
        </div>
        <div
          v-for="domain in domainList"
          :key="domain.id"
          @click="() => switchToAnotherDomain(domain)"
          :class="{ selected: domain.name === defaultDomainName }"
        >
          {{ domain.name }}
        </div>
      </div>
      <div class="logout" @click="logout">
        {{ $t("pageHeader.logoutMenu") }}
      </div>
    </div>
    <div
      class="overlay"
      v-if="!flags.isDesktopSwitcher && flags.showDomainSwitcherDropdown"
    ></div>
  </div>
</template>

<script>
import { DOMAIN_SWITCHER_TYPES, switchDomain } from "@/helpers/domainHelpers";
import { shouldHideDomainListForPartner } from "@/helpers/partner";
import bundleEvents from "@/telemetry/bundleEvents";
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters } from "vuex";

export default {
  name: "DomainSwitcher",
  mixins: [clickaway],
  props: {
    logout: Function,
    type: String,
  },
  data() {
    return {
      flags: {
        showDomainSwitcherDropdown: false,
        isDesktopSwitcher: this.isDesktopSwitcher(),
      },
    };
  },
  methods: {
    async switchToAnotherDomain(domain) {
      await bundleEvents.logDomainSwitched({ new_domain_name: domain });
      switchDomain(domain);
    },
    toggleBackgroundScroll(shouldScroll) {
      shouldScroll
        ? document.body.classList.remove("no-scroll")
        : document.body.classList.add("no-scroll");
    },
    toggleDomainSwitcherDropdown() {
      this.flags.showDomainSwitcherDropdown = !this.flags
        .showDomainSwitcherDropdown;

      if (!this.flags.isDesktopSwitcher)
        this.toggleBackgroundScroll(!this.flags.showDomainSwitcherDropdown);

      if (this.flags.showDomainSwitcherDropdown) {
        // log open domain switcher dropdown event
        bundleEvents.logDomainSwitcherClicked({
          domain_count: this.domainList.length,
        });
      }
    },
    closeDomainSwitcherDropdown() {
      if (this.flags.showDomainSwitcherDropdown) {
        this.flags.showDomainSwitcherDropdown = false;
        this.toggleBackgroundScroll(true);
      }
    },
    isDesktopSwitcher() {
      return this.type === DOMAIN_SWITCHER_TYPES.DESKTOP;
    },
  },
  computed: {
    ...mapGetters(["domainList", "partnerInfo"]),
    defaultDomainName() {
      return smartStorage.getItem("domain");
    },
    showDomainList() {
      const { partnerId } = this.partnerInfo;
      return (
        this.domainList.length > 1 && !shouldHideDomainListForPartner(partnerId)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.domain-switcher {
  position: relative;

  .domain-dropdown-items {
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    background: var(--tabBgCol);
    width: 100%;

    .domain-list {
      .switch-domain {
        font-size: 11px;
        font-weight: 600;
        color: var(--fadedTextCol);
        text-transform: uppercase;
      }

      div {
        color: var(--primaryTextCol);
        text-align: left;
        word-break: break-all;
      }
    }

    .logout {
      text-align: left;
      word-break: break-all;
      color: var(--cancelButtonCol);
    }
  }

  &.desktop {
    .domain-dropdown {
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../../assets/profile.svg") center no-repeat;
        vertical-align: middle;
        margin-right: 4px;
      }

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid var(--secondaryTextCol);
        display: inline-block;
        margin-left: 8px;
        position: relative;
        bottom: 2px;
      }
    }

    .domain-dropdown-items {
      position: absolute;
      top: 30px;
      right: 0;
      box-shadow: 0 0 8px -1px var(--boxShadowCol7);
      border-radius: 4px;
      width: 250px;

      .domain-list {
        div {
          padding: 10px 22px;

          &:hover {
            background: var(--readOnlyTxtBgCol);
            cursor: pointer;
          }

          &.selected {
            background: var(--readOnlyTxtBgCol);
            font-weight: 600;
          }
        }
      }
      .logout {
        padding: 10px 22px;
        border-top: 1px solid var(--lineSepaCol);

        &:hover {
          background: var(--readOnlyTxtBgCol);
          cursor: pointer;
        }
      }
    }
  }

  &.mobile {
    .overlay {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.25);
      z-index: 2;
    }

    .domain-dropdown {
      padding: 16px;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      background: #f0f0f0;
      border: 1px solid transparent;

      &:after {
        content: "";
        width: 25px;
        aspect-ratio: 1;
        background: url("../../assets/dropdown-arrow.svg");
        background-size: cover;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.open {
        background: #fff;
        border-color: var(--primaryBtnCol);
      }
    }

    .domain-dropdown-items {
      max-height: 250px;
      overflow-y: auto;
      box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.12);
      z-index: 9;
      border: 1px solid var(--lineSepaCol);
      border-radius: 0 0 4px 4px;

      .domain-list {
        div {
          word-break: break-all;
          padding: 12px 0;
          margin: 0 16px;

          &:not(:last-child) {
            border-bottom: 1px solid var(--lineSepaCol);
          }

          &.selected {
            pointer-events: none;
            cursor: default;

            &:after {
              content: "";
              width: 9px;
              aspect-ratio: 1/2;
              display: inline-block;
              position: absolute;
              border-bottom: 3px solid;
              border-right: 3px solid;
              transform: rotate(45deg);
              right: 20px;
            }
          }
        }
      }

      .logout {
        padding: 12px 0;
        margin: 0 16px;
        border-top: 1px solid var(--lineSepaCol);
        color: var(--cancelButtonCol);
      }
    }
  }
}
</style>
