<template>
  <transition name="modal-fade">
    <div class="modal-overlay">
      <div
        class="modal-body-container change-billin-cycle-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="Add card details for renewal"
      >
        <button
          type="button"
          class="btn-close"
          @click="close(false)"
          aria-label="Close modal"
          :disabled="showButtonSpinner"
        >
          &#215;
        </button>
        <div class="modal-content-wrapper">
          <h4>
            {{ $t("billingAndUpgrade.changeBillingCycle.heading") }}
          </h4>
          <ul>
            <li>
              <span>{{
                $t("billingAndUpgrade.changeBillingCycle.currentCycle")
              }}</span>
              <span>{{ $t(`billingCycles.${currentPlan.billingCycle}`) }}</span>
            </li>
            <li>
              <span>{{
                $t("billingAndUpgrade.changeBillingCycle.billingCycle")
              }}</span>
              <span>
                <select
                  v-model="billingFrequency"
                  @change="onBillingCycleChange"
                >
                  <option
                    v-bind:key="index"
                    v-for="(cycle, index) in supportedBillingCycles"
                    :value="cycle"
                  >
                    {{ $t(`billingCycles.${cycle}`) }}
                  </option>
                </select>
              </span>
            </li>
            <li
              v-for="(billing, index) in billingDetails"
              v-show="billing.isPaid !== false || billing.isTrial"
              v-bind:key="index"
            >
              <template v-if="billing.subType === 'plan'">
                <!-- trial -->
                <span>
                  <span v-if="isMailSuiteProduct(billing.product)">
                    {{ $t("mail") }}:
                  </span>
                  <span v-if="isSiteProduct(billing.product)">
                    {{ $t("site") }}:
                  </span>
                  {{
                    $t(`billingAndUpgrade.changeBillingCycle.title`, [
                      (billing.isTrial ? billing.paidPlan : billing).title,
                    ])
                  }}
                </span>
                <div>
                  <span
                    v-html="
                      $t(
                        isMailSuiteProduct(billing.product)
                          ? `billingAndUpgrade.changeBillingCycle.price`
                          : `billingAndUpgrade.changeBillingCycle.domainPrice`, // using domain price translation key for site pricing to save on translation cost
                        [
                          `${cr(
                            scaleUpToTwoDecimals(
                              (billing.isTrial ? billing.paidPlan : billing)
                                ?.planPricing?.[billingFrequency]?.[priceKey] ??
                                (billing.isTrial ? billing.paidPlan : billing)
                                  ?.pricing?.[billingFrequency]
                            )
                          )}`,
                        ]
                      )
                    "
                  />
                  <p
                    v-if="!isBillingFrequencyMonthly"
                    v-html="
                      $t(
                        isMailSuiteProduct(billing.product)
                          ? translationKeyForPlanPricing
                          : translationKeyForSitePricing,
                        [
                          `${cr(
                            scaleUpToTwoDecimals(
                              calculatePlanPricingCharge(
                                (billing.isTrial ? billing.paidPlan : billing)
                                  ?.planPricing?.[billingFrequency]?.[
                                  priceKey
                                ] ??
                                  (billing.isTrial ? billing.paidPlan : billing)
                                    ?.pricing?.[billingFrequency],
                                noOfMonths
                              )
                            )
                          )}`,
                        ]
                      )
                    "
                    class="plan-price"
                  />
                </div>
              </template>
              <template
                v-else-if="billing.subType === 'domain' && isDomainPaid"
              >
                <div>
                  <div v-if="isDomainProduct(billing.product)">
                    {{ $t("billingAndSubscription.domain") }}
                  </div>
                  <div class="subtext">
                    {{ billingData.domainDetails.name }}
                  </div>
                </div>

                <div>
                  <span
                    v-html="
                      $t(`billingAndUpgrade.changeBillingCycle.domainPrice`, [
                        `${cr(
                          scaleUpToTwoDecimals(
                            billingData.domainDetails?.planDetails
                              ?.planPricing?.[billingFrequency]?.[priceKey]
                          )
                        )}`,
                      ])
                    "
                  />
                  <p
                    v-if="!isBillingFrequencyMonthly"
                    v-html="
                      $t(translationKeyForDomainPricing, [
                        `${cr(
                          scaleUpToTwoDecimals(
                            calculatePlanPricingCharge(
                              billingData.domainDetails?.planDetails
                                ?.planPricing?.[billingFrequency]?.[priceKey],
                              noOfMonths
                            )
                          )
                        )}`,
                      ])
                    "
                    class="plan-price"
                  />
                </div>
              </template>
            </li>
          </ul>
          <p
            v-html="
              $t('billingAndUpgrade.changeBillingCycle.description', [
                $t(`billingCycles.${billingFrequency}`),
                formatDate(currentPlan.expiresOn),
              ])
            "
          />
          <button
            @click="changeBillingCycle"
            :class="['button', { 'show-spinner': showButtonSpinner }]"
            :disabled="showButtonSpinner || !cycleChanged"
          >
            {{ $t("billingAndUpgrade.changeBillingCycle.save") }}
          </button>
          <button @click="close(false)" class="button button__line-blue">
            {{ $t("billingAndUpgrade.changeBillingCycle.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {
  hasMailSuiteProduct,
  isDomainProduct,
  isMailSuiteProduct,
  isSiteProduct,
} from "@/helpers";
import * as helper from "../../helpers";
import { mixin as clickaway } from "vue-clickaway";
import { sendOrderAndCustomerEvent } from "@/telemetry/medusaUtils";
import { MEDUSA_EVENTS_MAP, SIDE_TAB_MEDUSA } from "@/telemetry/events";
import {
  BILLING_FREQUENCY,
  BILLING_CYCLE_SOURCES,
  BILLING_MONTHS,
  PLAN_BILLING_TRANSLATION_KEY_MAP,
  DOMAIN_BILLING_TRANSLATION_KEY_MAP,
} from "@/helpers/const";
import { sortBillingCycles } from "@/helpers/billingHelpers";
import billingService from "@/services/billing/billing.service";
import { mapGetters } from "vuex";
import bundleEvents from "@/telemetry/bundleEvents";
import { getSupportedBillingCyclesForNeo } from "@/helpers/neo.helper";

export default {
  name: "ChangeBillingCycle",
  mixins: [clickaway],
  data() {
    return {
      cr: helper.bindCurrencySymbol.bind(this),
      // currentPlan,
      cycleChanged: false,
      formatDate: helper.formatDate,
      toCapitalise: helper.toCapitalise,
      calculatePlanPricingCharge: helper.calculatePlanPricingCharge,
      showButtonSpinner: false,
      billingFrequency: this.$root.currentPlan.renewalBillingCycle,
      billingData: this.$props.billingDetails[0],
    };
  },
  props: {
    addons: {
      type: Array,
      default: [],
    },
    currency: {
      type: String,
    },
    billingDetails: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      isDomainPaid: "domain/isPaid",
      currentPlan: "currentPlanDetails",
      currentPlanMailDetails: "mail/currentProductDetails",
      currentPlanSiteDetails: "site/currentProductDetails",
    }),
    ...mapGetters(["products"]),
    planDetailsToUse() {
      return hasMailSuiteProduct(this.products)
        ? this.currentPlanMailDetails
        : this.currentPlanSiteDetails;
    },
    supportedBillingCycles() {
      let supportedBillingCycles = [];
      this.billingDetails.forEach((b) => {
        if (b.isPaid || b.isTrial) {
          Object.keys(
            b.isPaid ? b.planPricing ?? b.pricing : b.paidPlan.pricing
          ).forEach((k) => {
            if (
              k !== "isDiscountedPricing" &&
              supportedBillingCycles.indexOf(k) === -1
            ) {
              supportedBillingCycles.push(k);
            }
          });
        }
      });
      if (supportedBillingCycles.length === 0) {
        supportedBillingCycles.push("monthly", "yearly");
      }
      supportedBillingCycles = !helper.isNeoBrand()
        ? supportedBillingCycles
        : getSupportedBillingCyclesForNeo(
            supportedBillingCycles,
            this.currentPlan
          ).filter((cycle) => cycle !== "isDiscountedPricing");
      supportedBillingCycles = sortBillingCycles(supportedBillingCycles);

      return supportedBillingCycles;
    },
    isBillingFrequencyMonthly() {
      return this.billingFrequency === BILLING_FREQUENCY.MONTHLY;
    },
    priceKey() {
      const params = {
        isTrial: this.billingDetails[0].isTrial,
        firstBillingCycleUntil: this.currentPlan.firstBillingCycleUntil,
        sourceHook: BILLING_CYCLE_SOURCES.CHANGE_BILLING_CYCLE,
        trialUntil: this.currentPlan.trialUntil,
        expiresOn: this.currentPlan.expiresOn,
      };
      return helper.planPricingKey(params);
    },
    noOfMonths() {
      return BILLING_MONTHS[this.billingFrequency];
    },
    translationKeyForPlanPricing() {
      return `billingAndUpgrade.changeBillingCycle.${
        PLAN_BILLING_TRANSLATION_KEY_MAP[this.billingFrequency]
      }`;
    },
    translationKeyForSitePricing() {
      // we're reusing the same translation key as domain pricing
      // but for the sake of clarity, we've made a separate method
      return `billingAndUpgrade.changeBillingCycle.${
        DOMAIN_BILLING_TRANSLATION_KEY_MAP[this.billingFrequency]
      }`;
    },
    translationKeyForDomainPricing() {
      return `billingAndUpgrade.changeBillingCycle.${
        DOMAIN_BILLING_TRANSLATION_KEY_MAP[this.billingFrequency]
      }`;
    },
  },
  methods: {
    isDomainProduct,
    isSiteProduct,
    isMailSuiteProduct,
    scaleUpToTwoDecimals: helper.scaleUpToTwoDecimals,
    close(refetchCurrentPlan) {
      this.$emit("close", refetchCurrentPlan);
    },
    onBillingCycleChange() {
      this.cycleChanged = true;
    },
    handleAPIError(err) {
      this.showButtonSpinner = false;
      const message =
        (err && err.data && err.data.desc) ||
        this.$t(
          "billingAndUpgrade.addonPurchase.accountSelectSection.commonError"
        );
      this.showToast(message);
    },
    changeBillingCycle() {
      this.showButtonSpinner = true;
      billingService
        .changeBillingCycle(this.billingFrequency)
        .then(() => {
          this.showButtonSpinner = false;
          this.close(true);
          this.showToast(
            this.$t(`billingAndUpgrade.changeBillingCycle.message`, [
              this.toCapitalise(
                this.$t(`billingCycles.${this.billingFrequency}`)
              ),
            ]),
            { position: "bottom-center" }
          );
          if (this.currentPlan.billingCycle !== this.billingFrequency) {
            const medusaPayload = {
              new_billing_cycle: this.billingFrequency,
              source: helper.getAppSource(),
              source_hook: SIDE_TAB_MEDUSA.BILLING_AND_SUBSCRIPTION_TAB,
            };
            sendOrderAndCustomerEvent.call(
              this,
              {
                eventName: MEDUSA_EVENTS_MAP.BILLING_CYCLE_UPDATED,
                ...medusaPayload,
              },
              false
            );
            bundleEvents.logBillingCycleUpdated(medusaPayload);
          }
        })
        .catch(this.handleAPIError);
    },
  },
};
</script>
<style lang="scss">
.change-billin-cycle-modal {
  text-align: left;
  color: var(--primaryTextCol);
  min-width: unset;
  margin: 16px;

  select {
    width: 107px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--lineSepaCol);
    display: inline-block;
    outline: none;
    padding-inline: 10px;
  }

  ul {
    margin: 0;
    padding: 12px 0 6px;

    li {
      display: flex;
      padding: 12px 0;
      align-items: center;
      justify-content: space-between;

      & > div:last-of-type {
        text-align: right;
      }

      .subtext {
        color: var(--tertiaryTextCol);
      }

      & + li {
        border-top: solid 1px var(--lineSepaCol);
      }

      span:first-child {
        flex-basis: 250px;
      }
    }
  }

  #card-element {
    width: 100%;
    padding: 10px 5px;
    border-radius: 4px;
    border: solid 1px var(--disableCol);
    background-color: var(--tabBgCol);
  }

  .error {
    height: 15px;
    display: block;
    margin: 0 0 9px;
  }

  h4 {
    font-size: 20px;
    font-weight: normal !important;
    line-height: normal;
    color: var(--primaryTextCol);
  }

  p {
    margin: 8px 10px 28px 0;
    color: var(--secondaryTextCol);
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: var(--primaryTextCol);
  }

  .modal-content-wrapper button {
    padding: 10px 12px;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.show-spinner {
      @include button-loader();
    }
  }

  .plan-price {
    margin: 0;
  }
}
@import "../../styles/variables.scss";
@media screen and (max-width: $responsive-breakpoint) {
  .change-billin-cycle-modal {
    padding: 16px;
    ul {
      li {
        justify-content: space-between;
        span:first-child {
          flex-basis: 160px;
        }
        div {
          text-align: right;
        }
      }
    }
  }
}
</style>
