<template>
  <div>
    <div v-if="pageLoaded" class="view-container">
      <b-row>
        <b-col>
          <h4 class="emailHeader">
            {{ $t("emailAccounts.heading") }}
          </h4>
        </b-col>
        <b-col cols="8" v-if="hasEmails">
          <div class="search-container email-accounts-search">
            <span class="search-wrapper">
              <input
                type="text"
                class="input"
                v-model="search"
                v-bind:placeholder="$t('placeholders.searchUser')"
                autocomplete="new-password"
              />
              <span
                class="clear-search"
                v-show="search.length > 0"
                @click="search = ''"
              ></span>
            </span>
            <button
              class="button"
              @click="onCreateEmailAccountClick"
              :class="{ 'show-spinner': showSpinner }"
            >
              {{ $t("emailAccounts.createAccount") }}
            </button>
          </div>
        </b-col>
      </b-row>

      <create-email
        v-if="showCreateEmail"
        @close="showCreateEmail = false"
        :hasAdmin="hasAdmin"
        :view="view"
      />

      <div v-show="!hasEmails" class="card-container no-email-state">
        <div>
          <img :src="'empty-state' | themeImage" alt="" />
        </div>
        <p>{{ $t("emailAccounts.createAccountMsg") }}</p>
        <button class="button" @click="onCreateEmailAccountClick('list')">
          {{ $t("emailAccounts.createAccount") }}
        </button>
      </div>

      <div v-show="hasEmails" class="card-container">
        <table class="table email-list">
          <thead>
            <tr>
              <th
                v-bind:key="index"
                v-for="(field, index) in fields"
                scope="col"
              >
                {{ field }}
              </th>
            </tr>
          </thead>
          <tbody>
            <email-list
              :catchAllEmail="catchAllEmail"
              v-for="account in filteredList"
              :key="filteredList[account]"
              :user="account"
              :activeUserId="activeUserId"
              :setActiveUserId="setActiveUserId"
              :on-remove="onRemove"
              :view="view"
              :isLastAccount="isLastAccount"
              :isLastAdminAccount="isLastAdminAccount"
            ></email-list>
            <tr v-if="filteredList.length === 0" class="no-emails-found">
              <td colspan="3">
                {{ $t("emailAccounts.noAccountFound") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Loader v-else />
    <PaymentGatewayModal
      v-if="modalConfig.showModal"
      :config="modalConfig"
      :view="view"
    ></PaymentGatewayModal>
    <alt-user-email-modal
      v-if="showAltUserEmailModal"
      @close="closeAltUserEmailModal"
    />
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
import { mapGetters } from "vuex";
import expiredImg from "../../assets/expired.svg";
import upgradeFailImg from "../../assets/upgrade-failure.png";
import upgradeImage from "../../assets/upgrade-success.png";
import * as helper from "../../helpers";
import viewTypes from "../../views";
import AltUserEmailModal from "../Utility/AltUserEmailModal";
import Loader from "../Utility/Loader";
import PaymentGatewayModal from "../Utility/PaymentGatewayModals.vue";
import { partnerHandler, partnerMatcher } from "@/helpers/partner";
import CreateEmail from "./CreateEmail";
import EmailList from "./EmailList";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import emailService from "@/services/email/email.service";
import billingService from "@/services/billing/billing.service";
import ProductRouteGuardMixin from "@/mixins/ProductRouteGuardMixin";

export default {
  name: "EmailAccounts",
  components: {
    EmailList,
    CreateEmail,
    PaymentGatewayModal,
    AltUserEmailModal,
    Loader,
    BRow,
    BCol,
  },
  props: {
    view: Object,
    partnerId: Number,
  },
  mixins: [ProductRouteGuardMixin],
  data() {
    const hasLaunchEmailCreationFlag = !!smartStorage.getItem(
      "launchEmailCreation"
    );
    const hasShowAltUserEmailFlag = smartStorage.getItem("showAltUserEmail");
    const showCreateEmail =
      hasLaunchEmailCreationFlag &&
      !hasShowAltUserEmailFlag &&
      this.shouldOpenCreateEmailModal();
    return {
      showCreateEmail,
      showSpinner: false,
      catchAllEmail: "",
      search: "",
      accounts: [],
      hasAdmin: false,
      hasEmails: false,
      pageLoaded: false,
      addons: [],
      knownAddons: ["storage_addon", "titan_max"],
      modalCallback: () => {},
      fields: [
        this.$t("emailAccounts.createEmail.emailLabel"),
        this.$t("emailAccounts.status"),
        this.$t("emailAccounts.quota"),
      ],
      isSent: false,
      modalConfig: {},
      showAltUserEmailModal: hasShowAltUserEmailFlag,
      activeUserId: -1,
      theme: null,
      openPaymentURLInDiffTab:
        viewTypes[document.documentElement.getAttribute("view")][
          "openPaymentURLInDiffTab"
        ],
    };
  },
  computed: {
    ...mapGetters(["currentPlanDetails"]),
    ...mapGetters({
      plans: "mail/plans",
      isFreePlan: "mail/isFreePlan",
      isTrialPlan: "mail/isTrialPlan",
      currentPlan: "currentPlanDetails",
      currentPlanMailDetails: "mail/currentProductDetails",
    }),
    isPaid() {
      return this.currentPlanMailDetails.isPaid;
    },
    filteredList() {
      return this.accounts.filter((account) =>
        account.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    emailAccountCount() {
      return (
        this.currentPlanMailDetails.noOfAccounts?.limit ||
        this.currentPlanMailDetails.noOfAccounts?.purchased
      );
    },
    allowAccountLimit: function () {
      return this.emailAccountCount || "100000";
    },
    conjugatePlanOfTriaPlan() {
      //e.g Business Starter Trial => Business Starter
      return this.plans.find(
        (plan) => plan.id == this.currentPlanMailDetails.planDetails?.paidPlanId
      );
    },
    isAccountLimitReached() {
      const { limit, active } = this.currentPlanMailDetails.noOfAccounts;
      return limit == active;
    },
    isLastAccount() {
      return (
        helper.isNeoBrand() &&
        !helper.isLoginViaPartnerPanel() &&
        this.accounts?.length <= 1
      );
    },
    isLastAdminAccount() {
      return (
        helper.isNeoBrand() &&
        !helper.isLoginViaPartnerPanel() &&
        this.accounts.filter((account) => !!account?.isAdmin)?.length <= 1
      );
    },
  },
  methods: {
    setActiveUserId(id) {
      this.activeUserId = id;
    },
    closeAltUserEmailModal() {
      this.showAltUserEmailModal = false;
      if (!!smartStorage.getItem("launchEmailCreation")) {
        this.openCreateEmailModal();
      }
    },
    onCreateEmailAccountClick(type = "top-bar") {
      this.showSpinner = true;
      this.$emit("fetchCurrentPlan");
      let actionType = "";
      this.showSpinner = false;
      if (this.isTrialPlan && this.isAccountLimitReached) {
        actionType = "cantAddAccount";
        this.updateModalConfig({ type: "cantAddAccountForTrialPlan" });
      } else if (this.currentPlan.planExpiredError && !this.isFreePlan) {
        actionType = "planExpired";
        this.updateModalConfig({ type: "planExpired" });
      } else if (this.allowAccountLimit > this.accounts.length) {
        this.createEmailAccount(() => {});
      } else {
        actionType = "cantAddAccount";
        this.updateModalConfig({
          type: "cantAddAccountForNonTrialPlan",
          noOfAccounts: this.allowAccountLimit,
        });
      }
      let eventObj = {
        eventName: "mail_account_creation_initiated",
        partner_id: this.partnerId,
        source_hook:
          type === "list"
            ? "empty_screen_button"
            : "email_listing_screen_button",
      };
      if (actionType) eventObj["info"] = actionType;
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );
    },
    getEmailList() {
      if (!this.isSent) {
        this.isSent = true;
        this.fetchAddons()
          .then((data) => {
            // success
            this.addons = this.updatedRecords(
              data.addons.filter((a) => this.knownAddons.indexOf(a.type) !== -1)
            );
            return emailService.getEmailList();
          })
          .then(this.onSuccess)
          .catch(this.onError);
      }
    },
    getCatchAllEmail() {
      this.$http
        .get(`panel/domain/catchall/get`)
        .then(({ email }) => {
          this.catchAllEmail = email;
        })
        .catch((err) => console.error(err));
    },
    getAddonDetails(addon, key) {
      if (!addon) {
        return this.$t("billingAndUpgrade.orderSummarySection.addon"); // empty placehoder, incase of get all addons return empty but calc charge returns some addon
      }

      switch (addon.type) {
        case "storage_addon":
          const {
            respFeature,
            respAttr,
            attrValue,
          } = helper.getFeatureDetails.call(this, addon.features, "storage");
          return this.$t(
            `billingAndUpgrade.addons.${respFeature.type}.features.${respAttr}.${key}`,
            [attrValue]
          );

        default:
          return "";
      }
    },
    updatedRecords(records) {
      return records
        .map((r) => {
          r.features = r.features.map((f) => {
            if (f.type === "storage") {
              f.attrs = { ...f.attrs }; // create new copy of attr and then update, do not update original data
              const quota = parseInt(f.attrs.quota_in_mb, 10);
              const convertedQuota = (
                f.attrs.quota_in_gb || helper.toGB(f.attrs.quota_in_mb)
              ).toString();
              if (helper.isSizeInGB(quota)) {
                f.attrs.quota_in_gb = convertedQuota;
                delete f.attrs.quota_in_mb;
              } else {
                f.attrs.quota_in_mb = convertedQuota;
              }
            }

            return f;
          });

          if (!r.pricing) {
            return {
              ...r,
              pricing: { monthly: 0, yearly: 0 },
            };
          }

          return r;
        })
        .sort((a, b) => a.zIndex > b.zIndex);
    },
    fetchAddons() {
      return billingService.getAllAddons();
    },
    onSuccess(data) {
      if (data?.accounts?.length > 0) {
        data.accounts = data.accounts.map((ac) => {
          // mutation is necessary
          ac.addons = ac.addons || [];
          ac.addonHeadings = ac.addonHeadings || [];
          const cAddon = this.currentPlan.addons?.find(
            (ad) => ad.attachedTo === ac.id
          );
          if (cAddon) {
            ac.addons.push(cAddon.addonId);
            ac.addonHeadings.push(
              this.getAddonDetails(
                this.addons.find((ad) => ad.id === cAddon.addonId),
                "title"
              )
            );
          }

          return ac;
        });

        this.accounts = data.accounts;
        this.hasEmails = true;
        this.hasAdmin = !!data.accounts.find((a) => a.isAdmin);
      } else {
        this.hasEmails = false;
        this.hasAdmin = false;
      }
      this.isSent = false;
      this.pageLoaded = true;
      this.$store.dispatch("currentDomainMailboxDetails", data?.accounts || []);
    },
    onError(response) {
      console.error(response);
      if (response && response.data && response.data.desc) {
        this.showToast(response.data.desc);
      }
      this.isSent = false;
      this.pageLoaded = true;
    },
    onRemove(user) {
      this.accounts = this.accounts.filter((account) => account !== user);
    },
    updateModalConfig(data) {
      const isPaid = this.isPaid;
      switch (data.type) {
        case "planUpgraded":
          this.modalConfig = {
            className: this.view.showModalAsPage && "modalPage",
            imgUrl: upgradeImage,
            modalHeading: this.$t("planUpgradedModal.heading"),
            subHeading: this.$t("planUpgradedModal.description", [
              data.noOfAccounts,
              data.planType,
            ]),
            btnTxt: this.$t("planUpgradedModal.button"),
            btnCB: this.closeModal,
            showModal: true,
          };
          break;
        case "error":
          this.modalConfig = {
            className: this.view.showModalAsPage && "modalPage",
            imgUrl: upgradeFailImg,
            modalHeading: this.$t("errorModal.heading"),
            subHeading: this.$t("errorModal.description"),
            btnTxt: "",
            showModal: true,
          };
          break;
        case "planExpired":
          this.modalConfig = {
            imgUrl: expiredImg,
            className: this.view.showModalAsPage
              ? "error-modal modalPage"
              : "error-modal",
            imgHeight: 87,
            modalHeading: this.$t("planExpiredModal.heading"),
            subHeading: this.$t("planExpiredModal.createEmail"),
            btnTxt: this.$t("planExpiredModal.ok"),
            btnCB: this.closeModal,
            showModal: true,
          };
          break;
        case "cantAddAccountForTrialPlan":
          this.modalConfig = {
            themeImg: "used-license",
            themeImgExt: "png",
            imgWidth: 191,
            className: this.view.showModalAsPage && "modalPage",
            action: "buy-email-account",
            imgHeight: 200,
            modalHeading: this.$t("trialPlanAddAccount.heading"),
            subHeading: this.$t("trialPlanAddAccount.desc", [
              this.conjugatePlanOfTriaPlan.displayName,
              this.currentPlanMailDetails?.noOfAccounts?.limit,
            ]),
            btnTxt:
              smartStorage.getItem("paymentEnabled") &&
              smartStorage.getItem("paymentEnabled").toString() === "true"
                ? isPaid
                  ? this.$t("modalThree.buyButton")
                  : this.$t("modalThree.upgradeButton")
                : "",
            btnCB: this.buyMoreEmailAccounts,
            showModal: true,
          };
          break;
        case "cantAddAccountForNonTrialPlan":
          this.modalConfig = {
            themeImg: "used-license",
            themeImgExt: "png",
            imgWidth: 191,
            className: this.view.showModalAsPage && "modalPage",
            action: "buy-email-account",
            imgHeight: 200,
            modalHeading: this.$t(
              `modalThree.${isPaid ? "heading" : "headingThree"}`
            ),
            subHeading: isPaid
              ? this.$t("modalThree.subHeading", [
                  smartStorage.getItem("plan").toUpperCase(),
                ])
              : this.$t("modalThree.subHeadingThree", [
                  smartStorage.getItem("plan").toUpperCase(),
                  data.noOfAccounts,
                ]),
            btnTxt:
              smartStorage.getItem("paymentEnabled") &&
              smartStorage.getItem("paymentEnabled").toString() === "true"
                ? isPaid
                  ? this.$t("modalThree.buyButton")
                  : this.$t("modalThree.upgradeButton")
                : "",
            btnCB: this.buyMoreEmailAccounts,
            showModal: true,
          };
          break;
        case "purchasedAccounts":
          this.modalConfig = {
            className: this.view.showModalAsPage && "modalPage",
            imgUrl: upgradeImage,
            modalHeading: this.$t("purchasedAccountsModal.heading", [
              data.noOfAccounts,
            ]),
            subHeading: this.$t("purchasedAccountsModal.description"),
            btnTxt: this.$t("purchasedAccountsModal.button"),
            btnCB: this.createEmailAccount,
            showModal: true,
          };
          break;
        default:
          return false;
      }
    },
    closeModal(callback) {
      callback();
    },
    buyMoreEmailAccounts(callback) {
      this.modalCallback = callback;
      return billingService
        .checkPaymentType(this.isPaid ? "buyMoreAccounts" : "upgradePlan")
        .then(this.trasactionRedirectInitiate);
    },
    trasactionRedirectInitiate(data) {
      var eventObj = {
        eventName: "upgrade",
        source: helper.getAppSource(),
      };
      const callback = this.modalCallback;
      callback();
      if (
        helper.isAppLoadedInsideIframe() &&
        this.$store.getters.flags.disableIFramePaymentRedirection
      ) {
        helper.handleRedirectionOnPartnerSide(data.jwt);
        return;
      }
      const cpURL = encodeURIComponent(`${window.location.origin}/postPayment`);
      const redirectURL = `${data.makePaymentUrl}?jwt=${data.jwt}&redirect_url=${cpURL}`;
      this.medusaEvents
        .send(
          this.medusaEvents.createEventObj(
            eventObj,
            smartStorage.getItem("domain"),
            "flock_order"
          )
        )
        .then(() => {
          if (data.makePaymentUrl == "inAppPayment") {
            this.$router.push({
              path: this.isPaid ? "/buy-email-account" : "/billing-and-upgrade",
              query: {
                redirectionSource: "mailbox_limit_popup",
              },
            });
            return false;
          }

          smartStorage.hostRedirectURL = redirectURL;
          helper.hardRedirect(
            redirectURL,
            false,
            this.openPaymentURLInDiffTab &&
              data.makePaymentUrl !== "inAppPayment"
          ); // skip true, external URL
        })
        .catch(() => {
          smartStorage.hostRedirectURL = redirectURL;
          helper.hardRedirect(redirectURL); // skip true, external URL
        });
    },
    createEmailAccount(callback) {
      if (callback) {
        callback();
      }
      this.openCreateEmailModal();
    },
    shouldOpenCreateEmailModal() {
      // as we only domain partner will take care of email creation
      if (partnerMatcher.isOnyDomains(this.$store.getters.partnerId)) {
        partnerHandler.onlyDomainHandleEmailCreation();
        return false;
      }

      return true;
    },
    openCreateEmailModal() {
      if (!this.shouldOpenCreateEmailModal()) return false;

      this.showCreateEmail = true;
    },
  },
  watch: {
    pageLoaded: {
      immediate: true,
      handler(value) {
        this.$emit("pageLoaded", value);
      },
    },
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    this.theme = document.documentElement.getAttribute("theme");
    if (this.accounts.length === 0) {
      this.getEmailList();
      this.getCatchAllEmail();
    }
    if (this.$route.params.modalData) {
      let modalData = this.$route.params.modalData;
      this.updateModalConfig(modalData);
    }
    if (!smartStorage.getItem("firstRender")) {
      smartStorage.firstRender = true;
      var eventObj = {
        eventName: "cloud_control_panel_login",
        source: document.referrer.includes("flockmail") // TODO: Need to revisit this, should we consume source as query param?
          ? "login_button"
          : "cpanel_home",
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          smartStorage.getItem("domain"),
          "flock_order"
        )
      );
    }
  },
};
</script>

<style lang="scss">
.emailHeader {
  font-size: var(--h4Size);
}
.email-list {
  margin-bottom: 0;

  thead {
    tr {
      &:hover {
        background: transparent;
      }

      th {
        border-bottom: 1px solid var(--lineSepaCol);
        border-top: 0;
        color: var(--tableHeaderCol);

        &:first-of-type {
          width: 35%;
        }

        &:nth-of-type(2) {
          width: 20%;
        }
      }
    }
  }

  thead {
    tr {
      height: auto;

      th {
        padding-top: 0;
        padding-bottom: 20px;
      }
      &:hover {
        color: var(--primaryTextCol);
      }
    }
  }

  tr {
    height: 65px;
    position: relative;
    td {
      vertical-align: middle;
      line-height: 20px;
      border-bottom: 1px solid var(--lineSepaCol);
      position: relative;
      &:first-child {
        width: 300px;
        word-break: break-all;
      }
      &:last-child {
        text-align: right;
        position: relative;
      }
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    &.no-emails-found {
      td {
        width: 100%;
        text-align: center;
        padding: 40px 0;
      }
    }

    &:hover,
    &.current-item {
      background: var(--tableDataHoverCol);
      color: var(--genericTextCol);
      div {
        color: var(--genericTextCol);
      }
    }
  }
}
tr.email-accounts-row {
  &:hover {
    background: var(--tableDataHoverCol);
    color: var(--genericTextCol);
    td,
    div,
    p,
    li,
    span {
      color: var(--genericTextCol);
    }
  }
  .modal-overlay {
    .modal-content-wrapper {
      div:not(.error),
      p,
      li,
      span {
        color: var(--primaryTextCol);
      }
    }
  }
}

.email-accounts-search {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .email-accounts-search {
    flex-direction: row;
    align-items: flex-start;
    .search-wrapper {
      width: 40%;
      margin: 0 0 5px 0;
      margin-right: 20px;
    }
  }
}

.action-button {
  padding: 8px 12px;
  margin-right: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 55px;
  min-width: 140px;
}

.status {
  text-transform: capitalize;

  &:before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }

  &.active:before {
    background: var(--activeCol);
  }

  &.suspended:before {
    background: var(--warningCol);
  }
}

.usage-status,
table th {
  color: var(--primaryTextCol);
}

.usage-available {
  font-size: 12px;
}

.no-email-state {
  text-align: center;
  padding: 122px 24px 64px;

  img {
    margin-bottom: 24px;
  }
}

.overflow-grey {
  cursor: pointer;
  transform: rotate(90deg);
}
.show-spinner {
  @include button-loader();
}
</style>
