import store from "@/store";
import { isProdEnv } from ".";

export const NEO_PARTNER_ID_NUMBER = {
  dev: 71,
  stage: 71,
  preprod: 54,
  prod: 54,
};

export const HARD_CODED_PARTNER_CONF = {
  TSO: {
    PROD: {
      proTrial: {
        planId: 52,
      },
    },
  },
  // TODO: support for hardcoded OnlyDomains is valid till 2nd oct 2023
  // meantime they will implement the sync mechanism with bll
  ONLY_DOMAINS: {
    PROD: {
      id: 61,
      name: "OnlyDomains",
      createEmailURL: "https://onlydomains.com/businessEmail/index",
    },
    STAGE: {
      id: 66,
      name: "OnlyDomains",
      createEmailURL: "https://onlydomains.instra.com/businessEmail/index",
    },
  },
  RESELLER_CLUB: {
    PROD: {
      id: 72,
      name: "ResellerClub",
    },
    STAGE: {
      id: 116,
      name: "ResellerClub",
    },
  },
};

export const DOMAIN_LIST_HIDDEN_PARTNERS = [
  HARD_CODED_PARTNER_CONF.RESELLER_CLUB.PROD.id,
  HARD_CODED_PARTNER_CONF.RESELLER_CLUB.STAGE.id,
];

/**
 * conditions to match partner specific requirements
 * this can be further use to invoke corresponding partnerHandler
 */
export const partnerMatcher = {
  isTsoProTrialPlan(planId) {
    return (
      isProdEnv() &&
      parseInt(planId) === HARD_CODED_PARTNER_CONF.TSO.PROD.proTrial.planId
    );
  },
  isOnyDomains(partnerId) {
    const isProd = isProdEnv();
    const hasProdMatch =
      isProd &&
      parseInt(partnerId) === HARD_CODED_PARTNER_CONF["ONLY_DOMAINS"].PROD.id;
    const hasStageMatch =
      !isProd &&
      parseInt(partnerId) === HARD_CODED_PARTNER_CONF["ONLY_DOMAINS"].STAGE.id;
    return hasProdMatch || hasStageMatch;
  },
};

/**
 * this is for partner specific functionalities
 * NOTE: try to avoid hardcoded partner specific things
 */
export const partnerHandler = {
  onlyDomainHandleEmailCreation() {
    window.open(
      isProdEnv()
        ? HARD_CODED_PARTNER_CONF["ONLY_DOMAINS"].PROD.createEmailURL
        : HARD_CODED_PARTNER_CONF["ONLY_DOMAINS"].STAGE.createEmailURL
    );
  },
};

export const GODADDY_SUPPORT_URL =
  "https://in.godaddy.com/help/get-help-with-titan-email-42055";

export const getFreePlanChatSupportUrl = () => {
  return store.getters.partnerInfo?.urls?.freePlanChatSupport;
};

export const shouldHideDomainListForPartner = (partnerId) => {
  return DOMAIN_LIST_HIDDEN_PARTNERS.includes(partnerId);
};
