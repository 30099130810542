import { getDeviceId } from "@/helpers/device";
import store from "@/store";
import {
  returnObjectIfValueExists,
  getAppSource,
  hasMailSuiteProduct,
  hasSiteProduct,
  isNeoBrand,
  isNeoBusinessDomain,
  isMailSuiteProduct,
  toEpochMillis,
} from "@/helpers";
import { BUNDLE, CUSTOMER, NEO_OFFERING, ORDER } from "@/helpers/const";
import { getProductAttrs } from "@/telemetry/bundleEventHelpers";
import medusa from "@/telemetry/medusa";

const getOrderStatusOfCurrentDomain = () => {
  const currentDomain = store.getters.domainName;
  const domainFromStore = store.getters.domainList.find(
    (domain) => domain.name == currentDomain
  );
  return domainFromStore ? domainFromStore?.status : "";
};

const getBillingFrequency = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return currentPlan.autoRenew
    ? currentPlan.renewalBillingCycle
    : currentPlan.billingCycle;
};

export const isBillingFrequencyChanged = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return currentPlan.billingCycle !== getBillingFrequency();
};

// Write common medusa params of each here in this file
export const getCommonMedusaParams = () => {
  return {
    order_status: getOrderStatusOfCurrentDomain(),
    device_id: getDeviceId(),
    domain_name: store.getters["domain/domainName"],
    partner_id: +store.getters.partnerId,
    customer_id: +smartStorage.getItem("bllUserId"),
    plan_id: +store.getters["mail/planId"],
    plan_name: smartStorage.getItem("plan"),
    order_id: +store.getters["domain/domainID"],
    user_type: ORDER,
    neo_offering: isNeoBrand()
      ? isNeoBusinessDomain()
        ? NEO_OFFERING.COSITE
        : NEO_OFFERING.CUSTOM_DOMAIN
      : "",
    existing_account_count: +store.getters["currentDomainMailboxDetails"]
      ?.length,
    billing_cycle: store.getters.currentPlanDetails.billingCycle,
    expiry_date: toEpochMillis(store.getters.currentPlanDetails.expiresOn),
    source: getAppSource(),
    ...(smartStorage.getItem("role") === "accountAdmin" && {
      account_id: parseInt(smartStorage.getItem("accountId")),
    }),
    ...(isBillingFrequencyChanged()
      ? {
          new_billing_cycle: getBillingFrequency(), // FIXME: need to understand where/how this is used
        }
      : {}),
  };
};

export const getCommonMedusaParamsForUpgradePlan = (plan) => {
  return {
    new_plan_id: plan.id,
    new_plan_name: plan.type,
  };
};

export const getCommonMedusaParamsForCustomerEvents = () => {
  return {
    ...getCommonMedusaParams(),
    user_type: CUSTOMER,
    entityId: +smartStorage.getItem("bllUserId"),
  };
};

export const getCommonMedusaParamsForBundleEvents = (decorateEvent = true) => {
  return {
    ...(decorateEvent && {
      ...getCommonMedusaParams(),
      ...getProductAttrs(),
      auto_renew: store.getters.currentPlanDetails.autoRenew,
      bundle_id: +store.getters.bundleId,
      bundle_status:
        store.getters.activeProducts.length > 0 ? "active" : "suspended",
      order_count: store.getters.products.length,
      active_order_count: store.getters.activeProducts.length,
    }),
    user_type: BUNDLE,
  };
};

export const getMailProductAttr = (pId, isNewPlan) => {
  const products = store.getters["products"];
  const currentPlanMailDetails = store.getters["mail/currentProductDetails"];
  const planId = pId || currentPlanMailDetails.planId;
  const mailPlans = store.getters["mail/plans"];
  const keyPrefix = isNewPlan ? "mail_new_plan" : "mail_plan";

  if (hasMailSuiteProduct(products)) {
    return {
      [`${keyPrefix}_id`]: planId,
      [`${keyPrefix}_name`]:
        mailPlans.find((p) => p.id === planId)?.type || "unknown",
    };
  }

  return {};
};

export const getSiteProductAttr = (pId, isNewPlan) => {
  const products = store.getters["products"];
  const currentPlanSiteDetails = store.getters["site/currentProductDetails"];
  const planId = pId || currentPlanSiteDetails?.planId;
  const sitePlans = store.getters["site/plans"];
  const keyPrefix = isNewPlan ? "site_new_plan" : "site_plan";

  if (hasSiteProduct(products)) {
    return {
      [`${keyPrefix}_id`]: planId,
      [`${keyPrefix}_name`]:
        sitePlans.find((p) => p.id === planId)?.type || "unknown",
    };
  }

  return {};
};

export const getProductToPurchaseAttr = (record) => {
  return record
    ? isMailSuiteProduct(record.orderType)
      ? getMailProductAttr(record.id, true)
      : getSiteProductAttr(record.id, true)
    : {};
};

export const getCommonMedusaParamsForBundleBillingEvents = () => {
  const products = store.getters["products"];
  const mailProductDetails = store.getters["mail/currentProductDetails"];
  const currency = store.getters["currency"];
  const billing_country =
    store.getters["purchaseAndBillingDetails"]?.billing_country;
  const payment_card_type = store.getters["paymentCardType"];

  return {
    ...getMailProductAttr(),
    ...getSiteProductAttr(),
    ...(hasMailSuiteProduct(products)
      ? {
          mailbox_count: mailProductDetails.noOfAccounts.purchased,
        }
      : {}),
    ...returnObjectIfValueExists("payment_card_type", payment_card_type),
    ...returnObjectIfValueExists("billing_country", billing_country),
    currency,
  };
};

export function sendOrderAndCustomerEvent(event = {}, isCustomerEvent = true) {
  this.medusaEvents.trackEvent({
    ...getCommonMedusaParams(),
    ...event,
  });
  isCustomerEvent && isNeoBrand() && sendCustomerEvent(event);
}

export function sendCustomerEvent(event = {}) {
  medusa.trackEvent({
    ...getCommonMedusaParamsForCustomerEvents(),
    ...event,
    eventName: `${CUSTOMER}_${event.eventName}`,
  });
}

export async function sendBundleEvent(event = {}, decorateEvent = true) {
  await medusa.trackEvent({
    ...getCommonMedusaParamsForBundleEvents(decorateEvent),
    ...event,
  });
}

export async function sendOrderEvent(event = {}) {
  await medusa.trackEvent({
    ...getCommonMedusaParams(),
    auto_renew: store.getters.currentPlanDetails.autoRenew,
    bundle_id: +store.getters.bundleId,
    bundle_status: store.getters.activeProducts.length ? "active" : "suspended",
    order_count: store.getters.products.length,
    active_order_count: store.getters.activeProducts.length,
    ...event,
  });
}

export function triggerPurchaseAndBillingEvents(
  restMedusaParams,
  isCustomerEvent = true
) {
  const { eventName } = restMedusaParams;
  const billingParams = store.getters.purchaseAndBillingDetails;
  this.medusaEvents.trackEvent({
    ...getCommonMedusaParams(),
    ...billingParams,
    ...restMedusaParams,
  });
  isNeoBrand() &&
    isCustomerEvent &&
    this.medusaEvents.trackEvent({
      ...getCommonMedusaParamsForCustomerEvents(),
      ...billingParams,
      ...restMedusaParams,
      eventName: `${CUSTOMER}_${eventName}`,
    });
}
