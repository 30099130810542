import { isDevMode } from "@/helpers";
import {
  removeSensitiveDataFromSentryEvent,
  sentryCaptureException,
} from "@/helpers/sentry";
import router from "@/router";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

const backListedURLs = [
  /chrome-extension:\/\//,
  /moz-extension:\/\//,
  /safari-web-extension:\/\//,
];

export const initSentry = () => {
  // Initialize Sentry
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_BUILD_VERSION,
    environment: process.env.VUE_APP_ENV_FLAG,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    denyUrls: backListedURLs,
    ignoreErrors: backListedURLs,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // we'll capture all transactions for local development,
    // for the rest we'll set it to 10% to reduce incoming error logs
    tracesSampleRate: isDevMode() ? 1.0 : 0.1,
    beforeSend(event) {
      return removeSensitiveDataFromSentryEvent(event);
    },
  });

  // Set Vue global error handlers
  _initErrorHandler();
  _initUnhandledRejectionHandler();
};

const _initErrorHandler = () => {
  Vue.config.errorHandler = (err, vm, info) => {
    // keeping this log to identify the unhandled errors
    console.log("Global error handler", err);
    sentryCaptureException(
      err,
      {
        "vue-lifecycle": info,
        logType: "code_exception",
        errorType: err?.toString?.() || "unknown",
      },
      {
        componentName: vm?.$options?.name || "anonymous",
        propsData: vm?.$props,
        data: vm?.$data,
      }
    );
  };
};

const _initUnhandledRejectionHandler = () => {
  window.onunhandledrejection = (event) => {
    sentryCaptureException(event.reason, {
      logType: "unhandled_rejection",
    });
  };
};
